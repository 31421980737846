<template>
  <div>
    <div to="rightSidebar">
      <BusinessChat/>
    </div>
    <div class="grid-example mt-2">
        <b-row class="m-0 p-0">
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 3</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 4</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 5</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 6</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 7</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 8</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 9</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 10</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 11</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 12</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="2">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="2">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
          <b-col lg="2">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 3</div>
            </iq-card>
          </b-col>
          <b-col lg="2">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 4</div>
            </iq-card>
          </b-col>
          <b-col lg="2">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 5</div>
            </iq-card>
          </b-col>
          <b-col lg="2">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 6</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="3">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="3">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
          <b-col lg="3">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 3</div>
            </iq-card>
          </b-col>
          <b-col lg="3">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 4</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="4">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="4">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
          <b-col lg="4">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 3</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="5">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="5">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
          <b-col lg="2">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 3</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="6">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="6">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="7">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="5">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="8">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="4">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="9">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="3">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="10">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="2">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="11">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1</div>
            </iq-card>
          </b-col>
          <b-col lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 2</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="12">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1 The max widht we can use</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="11" offset-lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1 With offset</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="10" offset-lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1 With 2 offset</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="9" offset-lg="3">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Col 1 With 3 offset</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="7" offset-lg="2">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Groups Size Current</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="7" offset-lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Feed size</div>
            </iq-card>
          </b-col>
          <b-col lg="3" offset-lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Feed widgets</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="8">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Feed size previous</div>
            </iq-card>
          </b-col>
          <b-col lg="3" offset-lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Feed widgets</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="8">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Feed size HOMEiZ</div>
            </iq-card>
          </b-col>
          <b-col lg="4">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Feed widgets</div>
            </iq-card>
          </b-col>
        </b-row>
        <b-row class=" m-0 p-0">
          <b-col lg="8">
            <b-row>
              <b-col md="11" offset-lg="1">
                <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div class="text-center">Feed size more examples</div>
                </iq-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="3" offset-lg="1">
            <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="text-center">Feed widgets</div>
            </iq-card>
          </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
import { socialvue } from '@/config/pluginInit'
import BusinessChat from '@/components/homeiz/chatBars/BusinessChat'

export default {
  name: 'GridExample',
  components: {
    BusinessChat
  },
  mounted () {
    socialvue.index()
  },
  data () {
    return {
    }
  },
  computed: { },
  methods: {
  }
}
</script>

<style scoped>
</style>
