import { render, staticRenderFns } from "./GridExample.vue?vue&type=template&id=537a4556&scoped=true&"
import script from "./GridExample.vue?vue&type=script&lang=js&"
export * from "./GridExample.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "537a4556",
  null
  
)

export default component.exports