<template>
    <!-- Right Sidebar Panel Start-->
    <div :class="`right-sidebar-mini `+miniClass">
        <div class="right-sidebar-panel p-0">
            <!-- <div class="iq-card mb-0">
              <div class="iq-card-body p-0">
                <div class="media-height iq-chat-data-block active-block p-3">
                  <slot name="actions">
                    <div class="border-bottom">
                      <h6>Sponsored ads</h6>
                      <div class="pb-4">
                        <img src="@/assets/images/sponsored-ads.jpg" class="img-fluid" width="220" />
                      </div>
                    </div>
                  </slot>
                </div>
              </div>
            </div> -->
            <div class="iq-card mb-0">
                <div class="iq-card-body p-0">
                    <!-- chat!-->
                    <div class="media-height iq-chat-data-block active-block pr-3 pl-3 pt-1 pb-3">
                        <h5 v-if="title" class="text-capitalize text-center">
                          <slot name="headerTitle">
                            <img :src="renderIcon(icon)" :alt="title" class="img-fluid svg mr-1"/>
                            {{ title }}
                            <div v-if="subTitle">{{ subTitle }}</div>
                          </slot>
                        </h5>
                        <hr v-if="title">
                        <slot name="actions">
                          <div class="mb-3 d-flex justify-content-between align-items-center">
                            <h6>Contacts</h6>
                            <!-- <div class="d-flex font-20">
                              <i class="ri-edit-box-line mr-2"></i>
                              <i class="ri-more-line"></i>
                            </div> -->
                          </div>
                          <!-- <form action="#" class="searchbox search-box-user d-flex position-relative align-items-center mt-2 mb-4">
                            <input v-model="search" type="text" class="form-control router-link-active search-input" placeholder="Search messenger...">
                            <div class="comment-attagement d-flex">
                              <b-link href="javascript:void(0);">
                                <i class="ri-search-line mr-2"></i>
                              </b-link>
                            </div>
                          </form> -->
                        </slot>
                        <div v-for="(item,index) in filteredItems" :key="index" :id="`popover-user-${item.id}`" class="media cursor-pointer align-items-center mb-2">
                            <div class="iq-profile-avatar status-online">
                                <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
                            </div>
                            <div class="media-body ml-3">
                                <h6 class="mb-0 font-10-pt text-bold">{{item.fullName}}</h6>
                                <p class="mb-0 font-9-pt text-bold font-color-rgb-140">{{item.time}}</p>
                            </div>
                            <!-- <b-popover custom-class="user-info-popover" :target="`popover-user-${item.id}`" placement="left" triggers="hover focus">
                              <UserPopover :user="item"/>
                            </b-popover> -->
                        </div>
                    </div>
                    <div class="right-sidebar-toggle bg-martin mt-3" @click="toggleMini">
                        <i class="ri-arrow-left-line side-left-icon"></i>
                        <i class="ri-arrow-right-line side-right-icon"><span
                                class="ml-3 d-inline-block">Close Menu</span></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Right Sidebar Panel End-->
</template>
<script>
import loader from '@/assets/images/logo.png'
import darkLoader from '@/assets/images/logo-white.png'
// import UserPopover from '@/components/homeiz/user/UserPopoverPeep'

export default {
  name: 'ChatBar',
  components: {
    // UserPopover
  },
  props: {
    users: { required: true },
    title: { required: false, type: String },
    subTitle: { required: false, type: String, default: '' },
    toggleClass: { type: String, default: '' },
    minified: {
      default: false
    },
    icon: {
      type: String,
      default: '/icon/business-news-feed.svg'
    }
  },
  mounted () {
    this.miniClass = this.minified ? 'right-sidebar' : ''
    this.rightSideBarMini = this.minified
  },
  data () {
    return {
      miniClass: '',
      animated: { enter: 'zoomIn', exit: 'zoomOut' },
      animateClass: [
        { value: { enter: 'zoomIn', exit: 'zoomOut' }, text: 'Zoom' },
        { value: { enter: 'fadeInUp', exit: 'fadeOutDown' }, text: 'Fade' },
        { value: { enter: 'slideInLeft', exit: 'slideOutRight' }, text: 'Slide' },
        { value: { enter: 'rotateInDownLeft', exit: 'rotateOutDownLeft' }, text: 'Roll' }
      ],
      logo: loader,
      rtl: false,
      search: ''
    }
  },
  watch: {
    minified: function () {
      this.miniClass = this.minified ? 'right-sidebar' : ''
      this.rightSideBarMini = this.minified
    }
  },
  computed: {
    hasIconSlot () {
      return !!this.$slots.icon
    },
    hasOpenIconSlot () {
      return !!this.$slots.openIcon
    },
    hasCloseIconSlot () {
      return !!this.$slots.closeIcon
    },
    filteredItems () {
      return Object.values(this.users).filter(item => {
        return item.fullName.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    toggleMini () {
      this.rightSideBarMini = !this.rightSideBarMini
      this.checkRightSideBar()
    },
    checkRightSideBar () {
      if (this.rightSideBarMini) {
        this.miniClass = 'right-sidebar'
      } else {
        this.miniClass = ''
      }
    },
    rtlChange (mode) {
      this.rtl = mode
      this.modeChange({ rtl: mode, dark: this.darkMode })
      if (this.darkMode) {
        this.logo = darkLoader
      } else {
        this.logo = loader
      }
    },
    reset () {
      this.animated = { enter: 'zoomIn', exit: 'zoomOut' }
      this.rtlChange(false)
    },
    renderIcon (icon) {
      return require(`@/assets/images${icon}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  padding-right: 40px;
}

.search-box-user {
  .comment-attagement {
      right: 6px;

    i {
      font-size: 16px;
      color: var(--iq-primary);
    }
  }
}

.user-info-popover {
  left: -272px !important;
}
</style>
